import(/* webpackMode: "eager", webpackExports: ["Layout"] */ "/app/app/[locale]/_sections/layout.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@fortawesome+fontawesome-svg-core@6.7.2/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@grimme+ui-components@0.0.4-beta.91_@types+react-dom@18.3.5_@types+react@18.3.3__@types+react@18.3.3/node_modules/@grimme/ui-components/dist/lib/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@grimme+buttery@2.0.16_@emotion+is-prop-valid@1.3.1_@types+react-dom@18.3.5_@types+react@18.3.3__@types+react@18.3.3/node_modules/@grimme/buttery/dist/lib/index.css");
;
import(/* webpackMode: "eager" */ "/app/lib/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@14.2.23_@babel+core@7.26.8_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@14.2.23_@babel+core@7.26.8_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@15.1.7_next@14.2.23_@babel+core@7.26.8_react-dom@18.3.1_react@18.3.1__react@18.3.1__react@18.3.1/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.23_@babel+core@7.26.8_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/ui/providers/index.tsx");
